import React from "react";
import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface CustomTooltipProps {
  title: string;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 440,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dbdbdb",
  },
}));

export default function CustomTooltip({
  title,
}: CustomTooltipProps): JSX.Element {
  const renderTooltipContent = () => {
    return { __html: title };
  };

  return (
    <HtmlTooltip
      title={<span dangerouslySetInnerHTML={renderTooltipContent()} />}
      placement="top"
      arrow
    >
      <InfoOutlinedIcon
        sx={{
          color: "#0EA5E9",
          fontSize: "large",
          mt: "4px",
          ml: "5px",
          position: "absolute",
        }}
      />
    </HtmlTooltip>
  );
}
