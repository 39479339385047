// TokenHandler.js
import React, { useEffect } from "react";

const TokenHandler = () => {
  useEffect(() => {
    const token = localStorage.getItem("Token");

    console.log("Token value>>>>>>:", token);
  }, []);
  useEffect(() => {
    const extractTokenFromURL = () => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get("Token");
    };

    const storeToken = (token: any) => {
      localStorage.setItem("Token", token);
      console.log("urlParams", token);
    };

    const authToken = extractTokenFromURL();
    if (authToken) {
      storeToken(authToken);
    }
    console.log("Token", authToken);
  }, []);

  return null;
};

export default TokenHandler;
