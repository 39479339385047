import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AuthRoute from "./authroute";
import MainRoute from "./mainroute";
import TokenHandler from "src/pages/innerPages/webView/tokenHandler";

const Routes: React.FC = () => {
  const isAuthenticated = localStorage.getItem("Token");
  return (
    <Router>
      <TokenHandler />
      {isAuthenticated ? <MainRoute /> : <AuthRoute />}
    </Router>
  );
};

export default Routes;
