import React, { ReactNode } from "react";
import Navbar from "src/component/navbar/navbar";
import { useLocation } from "react-router-dom";
import Footer from "src/component/footer/footer";

interface AuthLayoutProps {
  children: ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const location = useLocation();

  const isLoginPage =
    location.pathname === "/login" || location.pathname === "/Sign_up";
  const isCompanyLoginPage = location.pathname === "/company";

  return (
    <div>
      {!isLoginPage && !isCompanyLoginPage && <Navbar />}
      {children}
      {!isCompanyLoginPage && !isLoginPage && <Footer />}
    </div>
  );
};

export default AuthLayout;
