import { Grid, Paper, Typography } from "@mui/material";

export default function About() {
  return (
    <>
      <Paper
        sx={{
          pt: "40px",
          boxShadow: "0 0 0 0",
          m: "-8px -8px 0px -8px",
          bgcolor: "#fff",
        }}
      >
        <Grid
          container
          sx={{
            mt: "0px",
            bgcolor: "#fff",
            p: "60px",
            borderRadius: "0px",
            justifyContent: "center",
            "@media (max-width: 599px)": {
              mt: "-40px",
            },
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              color: "#222",
              fontSize: "30px",
              fontWeight: "600",
            }}
          ></Typography>
        </Grid>
      </Paper>

      <Paper
        sx={{
          justifyContent: "center",
          boxShadow: "none",
          p: "40px 100px",
          background: "#fff",
          m: "-0px -8px 40px -8px",
          borderRadius: "0px",
          "@media (max-width: 899px)": {
            p: "30px 24px",
          },
        }}
      >
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              mt: "50px",
              color: "#222",
              "@media (max-width: 899px)": {
                my: "0px",
              },
            }}
          >
            <Typography
              sx={{ fontSize: "34px", fontWeight: "700", mb: "14px" }}
            >
              About Shree System's
            </Typography>

            <Typography sx={{ textAlign: "justify" }}>
              Welcome to Shree System's
              <br /> <br />
              Thank you for considering Ideal Systems. We look forward to
              [serving/connecting/helping] you! Follow us on Ideal Systems for
              updates and news.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
