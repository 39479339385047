import {
  TextField,
  TextFieldProps,
  InputAdornment,
  IconButton,
  Grid,
} from "@mui/material";
import {
  Control,
  Controller,
  ControllerProps,
  useFormContext,
} from "react-hook-form";
import React, { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import CustomTooltip from "src/component/uiElements/form/customTooltip";
import {
  Numeric,
  AlphaNumeric,
  EmailText,
  AlphaText,
} from "src/component/helper/inputTextType";
export type CustomTextFieldProps = Omit<TextFieldProps, "name"> & {
  validation?: ControllerProps["rules"];
  name: string;
  toolTipTitle?: string;
  control?: Control<any>;
  readOnly?: boolean;
  autoComplete?: string;
  length?: number;
  regex?: string;
  textTransform?: any;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  label?: string;
};

export default function CustomTextField({
  validation = {},
  type,
  required,
  name,
  toolTipTitle,
  readOnly,
  autoComplete,
  length,
  control,
  regex,
  textTransform,
  onChange,
  label,
  ...rest
}: CustomTextFieldProps): JSX.Element {
  const { register } = useFormContext();
  const [inputValue, setInputValue] = useState("");

  const handleClear = () => {
    setInputValue("");
    onChange &&
      onChange({
        target: { value: "" },
      } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={validation}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <Grid container alignItems="center">
          <Grid container alignItems="center">
            <Grid
              item
              sx={{
                fontSize: "14px",
                fontWeight: "550",
                mb: "0px",
                ml: "10px",
                mr: "6px",
              }}
            >
              {label}
            </Grid>
            {toolTipTitle && <CustomTooltip title={toolTipTitle} />}
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              {...rest}
              value={value || ""}
              required={required}
              type={type}
              {...register(name)}
              inputProps={{
                maxLength: length ? length : undefined,
                readOnly: readOnly ? readOnly : false,
                autoComplete: autoComplete ? autoComplete : undefined,
                style: {
                  textTransform: textTransform ? textTransform : "none",
                },
              }}
              sx={{
                mt: "8px",
                height: "auto !important",
                bgcolor: "#F5F5F5",
                borderRadius: "8px",
                "& .MuiInputLabel-root": {
                  color: "#212B36",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#F5F5F5",
                    borderRadius: "8px",
                  },
                  "&:hover fieldset": {
                    borderColor: "#F5F5F5",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#d0d0d0",
                  },
                },
              }}
              error={!!error}
              helperText={error ? error.message : rest?.helperText}
              onChange={(event) => {
                const inputValue = event.target.value;
                switch (regex) {
                  case Numeric:
                    const numericregex = /^[0-9_.]*$/;
                    if (numericregex.test(inputValue)) {
                      onChange(inputValue);
                    }
                    break;
                  case AlphaText:
                    const alphaTextregex = /^[a-zA-Z _]*$/;
                    if (alphaTextregex.test(inputValue)) {
                      onChange(inputValue);
                    }
                    break;
                  case AlphaNumeric:
                    const alphaNumbericregex = /^[a-zA-Z0-9_ .]*$/;
                    if (alphaNumbericregex.test(inputValue)) {
                      onChange(inputValue);
                    }
                    break;
                  case EmailText:
                    const Emailregex = /^[a-zA-Z0-9@.]*$/;
                    if (Emailregex.test(inputValue)) {
                      onChange(inputValue);
                    }
                    break;
                  default:
                    onChange(inputValue);
                    break;
                }
              }}
              InputProps={{
                endAdornment:
                  inputValue && !readOnly ? (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClear}>
                        <ClearIcon
                          sx={{ color: "#212B36", fontSize: "medium" }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
              }}
            />
          </Grid>
        </Grid>
      )}
    />
  );
}
