import { useEffect, useState } from "react";
import { Select as SELECT } from "src/component/api/Dashboard/index";
import { LOGOUT } from "src/component/helper/constants";
export type ImageProps = {
  list: any;
  ProductMasterID: number;
  ProductType: string;
  FileList: string;
  ProductName: string;
};
const UseGroupAdminDashboard = () => {
  const [user, setUser] = useState(0);
  const [totalExpence, setTotalExpence] = useState(0);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [loanAmount, setLoanAmount] = useState(0);
  const [groupIncome, setGroupIncome] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0); 
  const [groupName, setGroupName] = useState("");
  const [productPath, setProductPath] = useState("");
  const [list, setList] = useState<ImageProps[]>();
  const [startDate, setStartDate] = useState("");
  useEffect(() => {
    select();
  }, []);

  const select = async () => {
    const response = await SELECT();
    if (!response?.isAuthorized) LOGOUT();
    if (!response?.status) {
      return;
    }
    var data = JSON.parse(response?.data!);
    const productPath = data.ProductPath;
    const fileList = productPath.map(
      (item: { FileList: any }) => item.FileList
    );

    setList(fileList);
    setProductPath(fileList);
    setUser(data.TotalUser);
    setTotalExpence(data.TotalExpence);
    setTotalInvestment(data.TotalInvestment);
    setLoanAmount(data.TotalLoan);
    setGroupName(data.GroupName);
    setStartDate(data.StartDate);
    setGroupIncome(data.GroupIncome);
    setRemainingAmount(data.RemainingAmount);
  };

  return {
    user,
    totalExpence,
    totalInvestment,
    loanAmount,
    productPath,
    groupName,
    list,
    startDate,
    groupIncome,
    remainingAmount,
  };
};
export default UseGroupAdminDashboard;
