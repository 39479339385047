import {
  PostAPI,
  PostAPIWithoutRequestAndKeyData,
  PostAPIWithoutRequest,
} from "src/component/api/helper/apiHelper";

export const Insert = async (request: string) => {
  return PostAPI(request, "SavingGroups/Insert");
};
export const SelectSavingsGroupList = async () => {
  return PostAPIWithoutRequestAndKeyData("User/SelectSavingsGroupList");
};

export const SelectSavingsGroupListForGroupAdmin = async () => {
  return PostAPIWithoutRequest("SavingGroups/SelectGroupForGroupAdmin");
};
export const SelectReportForCompany = async (request: string) => {
  return PostAPI(request, "SavingGroups/SelectReportForCompany");
};
