import { useEffect, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { INSERT as Insert } from "src/component/api/addLoan/addLoan";
import {
  ToastCloseAll,
  ToastError,
  ToastSuccess,
} from "src/component/uiElements/toast";
import { LOGOUT } from "src/component/helper/constants";
import { SelectSavingsGroupListForGroupAdmin as SelectGroupList } from "src/component/api/savingsGroup/savingsGroup";
import { SelectMemberDetails as SelectRegistrationIDList } from "src/component/api/memberRegistration/memberRegistration";

import { SelectMemberForGroupAdmin as SelectMemberList } from "src/component/api/memberRegistration/memberRegistration";
import { SelectForDropdown as SelectLoanList } from "src/component/api/loanType/loanType";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
type LoanDetailsAddProps = {
  LoanType: LoanTypeProps;
  MemberName: MemberProps;
  LoanAmount: string;
  InterestRate: string;
  LoanTenure: string;
  MonthlyInstallment: string;
  LoanStartDate: string;
};

type MemberProps = {
  MemberRegistrationID: number;
  MemberName: string;
};

type LoanTypeProps = {
  LoanMasterID: number;
  LoanType: string;
};

const UseAddLoan = () => {
  const { t } = useTranslation("addLoanScreen");

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [registrationID, setRegistrationID] = useState("");
  const [groupName, setGroupName] = useState("");
  const [groupID, setGroupID] = useState(0);
  const [savingGroupList, setSavingGroupList] = useState([]);
  const [loanTypeList, setLoanTypeList] = useState([]);
  const [isShowDetails, setShowDetails] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [loanDuration, setLoanDuration] = useState("");
  const [loanAmount, setLoanAmount] = useState("");
  const [monthlyInstallment, setMonthlyInstallment] = useState("");
  const [isShowDuration, setShowDuration] = useState(false);
  const member = {
    MemberRegistrationID: 0,
    MemberName: t("Select member"),
  };

  const loantype = {
    LoanMasterID: 0,
    LoanType: t("Select loan type"),
  };
  const defaultValues = {
    LoanType: loantype,
    MemberName: member,
    LoanAmount: "",
    InterestRate: "",
    LoanTenure: "",
    MonthlyInstallment: "",
    LoanStartDate: "",
  };

  const validationSchema = Yup.object().shape({
    LoanAmount: Yup.string().required("Loan amount is required"),
    InterestRate: Yup.string().required("Interest rate is required"),
    MemberName: Yup.object().shape({
      MemberRegistrationID: Yup.number()
        .notOneOf([0], "Select member")
        .required("Select member"),
    }),
    LoanType: Yup.object().shape({
      LoanMasterID: Yup.number()
        .notOneOf([0], "Select loan type")
        .required("Select loan type"),
    }),
  });

  const formContext = useForm<LoanDetailsAddProps>({
    defaultValues,
    resolver: yupResolver(validationSchema) as any,
  });
  const { reset } = formContext;
  useEffect(() => {
    SelectMember();
    SelectGroup();
    SelectLoan();
  }, []);
  const resetMember = () => {
    reset();
  };
  const SelectMember = async () => {
    setMemberList([]);

    const response = await SelectMemberList();
    if (!response?.isAuthorized) LOGOUT();
    if (!response?.status) {
      let failResult = JSON.parse(response?.data!);
      ToastError(failResult.Message);
      return;
    }
    var data = JSON.parse(response?.data!);
    setMemberList(data);
  };

  const SelectRegistrationID = async (MemberRegistrationID: number) => {
    let intObj = {
      MemberRegistrationID: MemberRegistrationID,
    };
    let request = JSON.stringify(intObj);

    const response = await SelectRegistrationIDList(request);
    if (!response?.isAuthorized) LOGOUT();
    if (!response?.status) {
      let failResult = JSON.parse(response?.data!);
      ToastError(failResult.Message);
      return;
    }
    var data = JSON.parse(response?.data!);
    setRegistrationID(data.RegistrationID);
    setGroupName(data.GroupName);
    setGroupID(data.SavingGroupsID);
    setShowDetails(true);
  };

  const SelectLoan = async () => {
    setLoanTypeList([]);
    const response = await SelectLoanList();
    if (!response?.isAuthorized) LOGOUT();
    if (!response?.status) {
      let failResult = JSON.parse(response?.data!);
      ToastError(failResult.Message);
      return;
    }
    var data = JSON.parse(response?.data!);
    setLoanTypeList(data);
  };
  const SelectGroup = async () => {
    setSavingGroupList([]);
    const response = await SelectGroupList();
    if (!response?.isAuthorized) LOGOUT();
    if (!response?.status) {
      let failResult = JSON.parse(response?.data!);
      ToastError(failResult.Message);
      return;
    }
    var data = JSON.parse(response?.data!);
    setSavingGroupList(data);
  };
  const onSubmit: SubmitHandler<LoanDetailsAddProps> = async (values) => {
    ToastCloseAll();
    let inputObj = {
      RegistrationID: registrationID,
      SavingGroupsID: groupID,
      LoanTenure: loanDuration,
      LoanMasterID: values.LoanType.LoanMasterID,
      MemberRegistrationID: values.MemberName.MemberRegistrationID,
      LoanAmount: values.LoanAmount,
      LoanStartDate: values.LoanStartDate,
      InterestRate: values.InterestRate,
      MonthlyInstallment: values.MonthlyInstallment,
    };
    let request = JSON.stringify(inputObj);
    setLoading(true);
    setResult([]);
    var response = await Insert(request);
    setLoading(false);
    if (!response?.isAuthorized) LOGOUT();
    if (!response?.status) {
      let failResult = JSON.parse(response?.data!);
      ToastError(failResult.Message);
      return;
    }
    let result = JSON.parse(response?.data!);
    resetMember();
    setShowDetails(false);
    setShowDuration(false);
    ToastSuccess(result.Message);
  };

  return {
    result,
    loading,
    formContext,
    memberList,
    savingGroupList,
    isShowDetails,
    registrationID,
    loanTypeList,
    groupName,
    loanDuration,
    loanAmount,
    monthlyInstallment,
    isShowDuration,
    setShowDuration,
    setMonthlyInstallment,
    setLoanAmount,
    setLoanDuration,
    SelectRegistrationID,
    onSubmit,
  };
};
export default UseAddLoan;
