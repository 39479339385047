import { Box, Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CustomPaper from "src/component/uiElements/container/customPaper";
import { FormProvider } from "react-hook-form";
import CustomTextField from "src/component/uiElements/form/customTextField";
import { CustomSecondaryButton } from "src/component/uiElements/buttons/secondaryButton";
import { CustomPrimaryButton } from "src/component/uiElements/buttons/primaryButton";
import PageHeading from "src/component/pageHeading/pageHeading";
import CustomDatePickerField from "src/component/uiElements/form/customDatePickerField";
import CustomAutoCompleteSelect from "src/component/uiElements/form/customAutoCompleteSelect";
import { Numeric } from "src/component/helper/inputTextType";
import UseAddLoan from "./useAddLoan";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
const AddLoanScreen = () => {
  const {
    formContext,
    loading,
    isShowDetails,
    registrationID,
    memberList,
    loanTypeList,
    groupName,
    loanDuration,
    loanAmount,
    monthlyInstallment,
    isShowDuration,
    setShowDuration,
    setMonthlyInstallment,
    setLoanAmount,
    setLoanDuration,
    SelectRegistrationID,
    onSubmit,
  } = UseAddLoan();

  const { handleSubmit, reset } = formContext;
  const handleDetails = (item: any) => {
    SelectRegistrationID(item.MemberRegistrationID);
  };
  const { t } = useTranslation('addLoanScreen');
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      calculateLoanDuration();
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [monthlyInstallment]);

  const calculateLoanDuration = () => { 
    const amount = parseFloat(loanAmount);
    const installment = parseFloat(monthlyInstallment); 
    if (!isNaN(amount) && !isNaN(installment) && installment !== 0) {
      const duration = Math.ceil(amount / installment);
      setLoanDuration(duration.toString());
    } else {
      setLoanDuration("");
    }
    setShowDuration(true);
  };
  const handleKeyUp = (
    e: React.KeyboardEvent<any>,
    setterFunction: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const inputValue = (e.target as HTMLInputElement).value;
    setterFunction(inputValue);
  };
  return (
    <>
      <Box sx={{ m: "24px" }}>
        <Grid container sx={{ justifyContent: "center" }}>
          <Grid item xs={12} sm={12} md={12} lg={11} xl={11}>
            <CustomPaper>
              <Grid sx={{ textAlign: "center" }}>
                <PageHeading pageName={t("Add Loan")} />
              </Grid>
              <FormProvider {...formContext}>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                  style={{ width: "100%" }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <CustomAutoCompleteSelect
                        fullWidth
                        fieldName="MemberRegistrationID"
                        valueKey="MemberRegistrationID"
                        labelKey="MemberName"
                        label={t("MemberName")}
                        placeholder={t("MemberName")}
                        name={"MemberName"}
                        options={memberList}
                        onChange={handleDetails}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <CustomTextField
                        required
                        fullWidth
                        margin={"dense"}
                        label={t("LoanAmount")}
                        name={"LoanAmount"}
                        placeholder={t("LoanAmount")}
                        length={50}
                        regex={Numeric}
                        onKeyUp={(e) => handleKeyUp(e, setLoanAmount)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <CustomTextField
                        required
                        fullWidth
                        margin={"dense"}
                        name={"MonthlyInstallment"}
                        label={t("MonthlyInstallment")}
                        placeholder={t("MonthlyInstallment")}
                        length={50}
                        regex={Numeric}
                        onKeyUp={(e) => handleKeyUp(e, setMonthlyInstallment)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                      sx={{ mt: "3px" }}
                    >
                      <CustomDatePickerField
                        name="LoanStartDate"
                        label={t("LoanStartDate")}
                        disableFutureDates={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <CustomAutoCompleteSelect
                        fullWidth
                        fieldName="LoanMasterID"
                        valueKey="LoanMasterID"
                        labelKey="LoanType"
                        label={t("LoanType")}
                        name={"LoanType"}
                        options={loanTypeList}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <CustomTextField
                        required
                        fullWidth
                        margin={"dense"}
                        label={t("InterestRate")}
                        name={"InterestRate"}
                        placeholder={t("InterestRate")}
                        length={50}
                        regex={Numeric}
                      />
                    </Grid>
                    {isShowDuration && (
                      <>
                        {loanDuration && (
                          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Grid container spacing={2}>
                              {loanDuration && (
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  sx={{}}
                                >
                                  <Box
                                    sx={{
                                      bgcolor: "#F5F5F5",
                                      height: "42px",
                                      borderRadius: "6px",
                                      textAlign: "center",
                                      pt: "9px",
                                      mt: "24px",
                                    }}
                                  >
                                    <Typography>
                                      {t("Loan Duration")}: {loanDuration} {t("months")} 
                                    </Typography>
                                  </Box>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        )}
                      </>
                    )}
                    {isShowDetails && (
                      <>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Grid container spacing={2}>
                            {groupName && (
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                sx={{}}
                              >
                                <Box
                                  sx={{
                                    bgcolor: "#F5F5F5",
                                    height: "42px",
                                    borderRadius: "6px",
                                    textAlign: "center",
                                    pt: "9px",
                                    mt: "24px",
                                  }}
                                >
                                  <Typography>
                                    {t("GroupName")}:{groupName}
                                  </Typography>
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Grid container spacing={2}>
                            {registrationID && (
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                sx={{}}
                              >
                                <Box
                                  sx={{
                                    bgcolor: "#F5F5F5",
                                    height: "42px",
                                    borderRadius: "6px",
                                    textAlign: "center",
                                    pt: "9px",
                                    mt: "24px",
                                  }}
                                >
                                  <Typography>
                                    Registration id:{registrationID}
                                  </Typography>
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid
                      container
                      spacing={2}
                      sx={{ justifyContent: "flex-end" }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={2}
                        lg={2}
                        xl={2}
                        sx={{
                          mt: "33px",
                          "@media (max-width: 900px)": {
                            mt: "12px",
                          },
                        }}
                      >
                        <CustomSecondaryButton
                          title={"Cancel"}
                          loading={loading}
                          onClick={() => reset()}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={2}
                        lg={2}
                        xl={2}
                        sx={{
                          mt: "33px",
                          "@media (max-width: 900px)": {
                            mt: "0px",
                          },
                        }}
                      >
                        <CustomPrimaryButton
                          title={"Add"}
                          loading={loading}
                          endIcon={<AddIcon />}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </FormProvider>
            </CustomPaper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default AddLoanScreen;
