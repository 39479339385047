import { Typography } from "@mui/material";

interface PageHeadingProps {
    pageName: string;
}

function PageHeading(props: PageHeadingProps) {
    return (
        <Typography
            sx={{
                mb: "18px",
                fontSize: "24px",
                fontWeight: "600", 
                color: "#222",
                borderBottom: "1px solid #ccc",
                pb: "6px",
            }}
        >
            {props.pageName}
        </Typography>
    );
}

export default PageHeading;
