import { Grid, TextField, InputAdornment } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Moment from "moment";
import CustomTooltip from "./customTooltip";

export type CustomDatePickerFieldProps = {
  name: string;
  label: string;
  toolTipTitle?: string;
  disableFutureDates?: boolean;
  placeholder?: string;
  format?: string;
};
export default function CustomDatePickerField({
  name,
  label,
  toolTipTitle,
  disableFutureDates = false,
  placeholder,
  format,
}: CustomDatePickerFieldProps): JSX.Element {
  const { control } = useFormContext();
  const defaultDate = new Date();
  const maxDate = disableFutureDates ? defaultDate : undefined;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({ field: { onChange, value } }) => (
        <Grid container alignItems="center">
          <Grid container alignItems="center">
            <Grid
              item
              sx={{
                fontSize: "14px",
                fontWeight: "550",
                mb: "4px",
                ml: "4px",
                mr: "6px",
              }}
            >
              {label}
            </Grid>
            {toolTipTitle && <CustomTooltip title={toolTipTitle} />}
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="date"
              size="small"
              value={Moment(value).format("YYYY-MM-DD")}
              InputProps={{
                style: {
                  textTransform: "none",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    {value === null ||
                      (value === "" && (
                        <span
                          style={{
                            color: "rgba(0, 0, 0, 0.5)",
                            background: "#F5F5F5",
                            pointerEvents: "none",
                          }}
                        >
                          {placeholder}
                        </span>
                      ))}
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                const inputValue = e.target.value;
                const selectedDate = inputValue ? new Date(inputValue) : null;

                onChange(selectedDate);
              }}
              inputProps={{
                max: maxDate,
              }}
              sx={{
                "& input": {
                  paddingTop: "12px",
                  bgcolor: "#F5F5F5", // Adjust the padding here to align the text
                },
              }}
            />
          </Grid>
        </Grid>
      )}
    />
  );
}
