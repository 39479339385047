
import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { SxProps, ButtonProps } from "@mui/material";

type CustomButtonProps = ButtonProps & {
  title: string;
  loading?: boolean;
  sxProps?: SxProps | undefined;
  backgroundColor?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export const CustomSecondaryButton: React.FC<CustomButtonProps> = ({
  title,
  loading,
  sxProps,
  ...props
}: CustomButtonProps) => {

  return (
    <>
      <LoadingButton
        fullWidth
        variant="outlined"
        type="submit"
        loading={loading}
        sx={[
          {
            boxShadow: "0 0 0 0",
            height: "44px",
            border: "1px solid #000",
            color: "#000",
            borderRadius: "12px",
            fontSize: "14px",
            fontWeight: "400",
            textTransform: "none",
            "&:hover": {
              boxShadow: "0 0 0 0",
              bgcolor: "#222",
              border: "0px",
              color: "#fff",
            },
          },
          ...(Array.isArray(sxProps) ? sxProps : [sxProps]),
        ]}
        {...props}
      >
        {title}
      </LoadingButton>
    </>
  );
};
