import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AuthLayout from "src/component/navigation/publicNavbar";
import About from "src/pages/outerPages/about/about";

const LoginScreen = lazy(
  () => import("src/pages/outerPages/login/loginScreen")
);
const ForgetPasswordScreen = lazy(
  () => import("src/pages/outerPages/forgotPassword/forgotPasswordScreen")
);
const HomeScreen = lazy(() => import("src/pages/outerPages/home/home"));
const SignUpScreen = lazy(
  () => import("src/pages/outerPages/sign_up/signUpScreen")
);
const CompanyLoginScreen = lazy(
  () => import("src/pages/outerPages/companyLogin/companyLoginScreen")
);

const SubscriptionPaymentDetails = lazy(() =>
  import(
    "src/pages/outerPages/subscriptionPaymentDetails/subscriptionPaymentDetails"
  ).then((module) => ({ default: module.SubscriptionPaymentDetails }))
);
const OutSideSubscriptionPaymentScreen = lazy(() =>
  import(
    "src/pages/outerPages/outSideSubscriptionPayment/outSideSubscriptionPaymentScreen"
  ).then((module) => ({ default: module.OutSideSubscriptionPaymentScreen }))
); 

const PrivacyAndPolicyScreen = lazy(
  () => import("src/pages/outerPages/privacyAndPolicy/privacyAndPolicy")
);
const TermsConditionScreen = lazy(
  () => import("src/pages/outerPages/termsConditions/termsConditionScreen")
);
const CancellationRefundPolicy = lazy(
  () =>
    import(
      "src/pages/outerPages/cancellationRefundPolicy/cancellationRefundPolicy"
    )
);
const ContactUs = lazy(
  () => import("src/pages/outerPages/contactUs/contactUs")
);
const AuthRoute: React.FC = () => {
  return (
    <AuthLayout>
      <Suspense>
        <Routes>
          <Route path="/Sign_up" element={<SignUpScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/company" element={<CompanyLoginScreen />} />
          <Route path="/forgot_password" element={<ForgetPasswordScreen />} />
          <Route path="/home" element={<HomeScreen />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy_policy" element={<PrivacyAndPolicyScreen />} />
          <Route path="/terms_conditions" element={<TermsConditionScreen />} />
          <Route
            path="/cancellation_refund_policy"
            element={<CancellationRefundPolicy />}
          />
          <Route path="/contact_us" element={<ContactUs />} />
          <Route path="/" element={<HomeScreen />} />
          <Route
            path="/SubscriptionPayment"
            element={
              <SubscriptionPaymentDetails
                isOpen={true}
                title={""}
                negative_button_click={function (value: boolean): void {
                  throw new Error("Function not implemented.");
                }}
              />
            }
          />
          <Route
            path="/Subscription_Payment"
            element={
              <OutSideSubscriptionPaymentScreen
                isOpen={true}
                title={""}
                negative_button_click={function (value: boolean): void {
                  throw new Error("Function not implemented.");
                }}
              />
            }
          />
        </Routes>
      </Suspense>
    </AuthLayout>
  );
};

export default AuthRoute;
