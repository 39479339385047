import { PostAPI } from "src/component/api/helper/apiHelper";

export const INSERT = async (request: string) => {
  return PostAPI(request, "LoanDetails/Insert");
};
export const Select = async (request: string) => {
  return PostAPI(request, "LoanDetails/Select");
};
export const SelectLoanDetails = async (request: string) => {
  return PostAPI(request, "LoanDetails/SelectLoanDetails");
};
export const Delete = async (request: string) => {
  return PostAPI(request, "LoanDetails/Delete");
};
