import { Paper } from "@mui/material";

import React, { ReactNode } from "react";

type Props = {
  children?: ReactNode;
};

export default function CustomPaper({ children }: Props) {
  return (
    <Paper
      sx={{
        p: "20px",
        maxWidth: "100%",
        borderRadius: "12px",
        boxShadow:
          "0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
        bgcolor: "#ffffff",
      }}
    >
      {children}
    </Paper>
  );
}
