import { useEffect, useState } from "react";
import { SelectForMember as SELECT } from "src/component/api/Dashboard/index";
import { LOGOUT } from "src/component/helper/constants";

const UseMemberDashboard = () => {
  const [groupAdmin, setGroupAdmin] = useState(0);
  const [totalUser, setTotalUser] = useState(0);
  const [totalSubscribers, setSubscribers] = useState(0);
  const [groupName, setGroupName] = useState("");
  const [startDate, setStartDate] = useState("");
  useEffect(() => {
    select();
  }, []);

  const select = async () => {
    const response = await SELECT();
    if (!response?.isAuthorized) LOGOUT();
    if (!response?.status) {
      return;
    }
    var data = JSON.parse(response?.data!);
    setGroupAdmin(data.TotalGroupAdmin);
    setTotalUser(data.TotalUser);
    setGroupName(data.GroupName);
    setStartDate(data.StartDate);
  };

  return {
    groupAdmin,
    totalUser,
    groupName,
    startDate,
    totalSubscribers,
  };
};
export default UseMemberDashboard;
