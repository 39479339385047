import React from "react";
import {
  Autocomplete,
  TextField,
  TextFieldProps,
  SxProps,
  Grid,
} from "@mui/material";
import {
  Control,
  Controller,
  ControllerProps,
  useFormContext,
} from "react-hook-form";
import CustomTooltip from "./customTooltip";

export type AutoCompleteSelectProps = Omit<
  TextFieldProps,
  "name" | "type" | "onChange"
> & {
  validation?: ControllerProps["rules"];
  name: string;
  toolTipTitle?: string;
  options?: any[];
  valueKey?: string;
  labelKey?: string;
  type?: "string" | "number";
  sxProps?: SxProps;
  objectOnChange?: boolean;
  onChange?: (value: any) => void;
  control?: Control<any>;
  fieldName: string;
  label?: string;
};

export default function CustomAutoCompleteSelect({
  name,
  toolTipTitle,
  required,
  fieldName,
  valueKey = "id",
  labelKey = "title",
  options = [],
  sxProps,
  objectOnChange,
  validation = {},
  control,
  label,
  ...rest
}: AutoCompleteSelectProps): JSX.Element {
  const { setValue, getValues } = useFormContext();

  return (
    <Controller
      name={name}
      rules={validation}
      control={control}
      render={({
        field: { onBlur, onChange, value },
        fieldState: { error },
      }) => {
        return (
          <Autocomplete
            options={options}
            autoHighlight
            value={getValues(name)}
            defaultValue={[]}
            onChange={(event, item) => {
              onChange(item);
              if (typeof rest.onChange === "function") {
                if (objectOnChange) {
                  item = options.find((i) => i[valueKey] === item);
                }
                rest.onChange(item);
              }
              setValue(fieldName, item[valueKey]);
            }}
            getOptionLabel={(option) => option[labelKey]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <Grid container alignItems="center">
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Grid container alignItems="center">
                        <Grid
                          item
                          sx={{
                            fontSize: "14px",
                            fontWeight: "550",
                            mb: "0px",
                            ml: "10px",
                            mr: "6px",
                          }}
                        >
                          {label}
                        </Grid>
                        {toolTipTitle && <CustomTooltip title={toolTipTitle} />}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <TextField
                  {...params}
                  name={name}
                  variant="outlined"
                  fullWidth
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                  error={!!error}
                  helperText={error ? error.message : rest?.helperText}
                  required={required}
                  sx={{
                    mt: "8.3px",
                    bgcolor: "#F5F5F5",
                    borderRadius: "8px",
                    "& .MuiInputLabel-root": {
                      color: "#212B36",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#F5F5F5",
                        borderRadius: "8px",
                      },
                      "&:hover fieldset": {
                        borderColor: "#F5F5F5",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#d0d0d0",
                      },
                    },
                  }}
                />
              </Grid>
            )}
          />
        );
      }}
    />
  );
}
