import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AuthInnerNavbar from "src/component/navbar/authInnerNavbar";
import { LOGOUT } from "src/component/helper/constants";
import { useDispatch } from "react-redux";
import AddLoanScreen from "src/pages/innerPages/add_loan/addLoanScreen"; 

const CompanySupportDashboardScreen = lazy(
  () => import("src/pages/innerPages/dashborad/companySupport/companySupportDashboardScreen")
);
const MemberRegistrationScreen = lazy(
  () =>
    import("src/pages/innerPages/memberRegistration/memberRegistrationScreen")
);
const EmployeeRegistrationScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/employeeRegistration/employeeRegistrationScreen"
    )
);
const SavingsGroupRegistrationScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/savingsGroupRegistration/savingsGroupRegistrationScreen"
    )
);
const RegisterScreen = lazy(
  () => import("src/pages/innerPages/register/registerScreen")
);
const LoanReportScreen = lazy(
  () => import("src/pages/innerPages/reports/loan_report/loanReportScreen")
);
const PayInstallmentScreen = lazy(
  () => import("src/pages/innerPages/payInstallment/payInstallmentScreen")
);
const InstallmentReportScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/reports/installment_Report/installmentReportScreen"
    )
);
const RegularBachatReportScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/reports/regularBachat_report/regularBachatReportScreen"
    )
);
const OtherInvestmentScreen = lazy(
  () => import("src/pages/innerPages/otherInvestment/otherInvestmentScreen")
);
const ExpenseTypeAddScreen = lazy(
  () => import("src/pages/innerPages/expense_type/expenseTypeAddScreen")
);
const ExpenseSubTypeScreen = lazy(
  () => import("src/pages/innerPages/expense_subtype/expenseSubTypeScreen")
);
const ExpenseConfigurationScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/expense_configuration/expenseConfigurationAddScreen"
    )
);
const AddExpenseClaimScreen = lazy(
  () => import("src/pages/innerPages/addExpenseClaim/addExpenseClaimScreen")
);
const ExpenseClaimReportScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/reports/expense_claim_report/expenseClaimReportScreen"
    )
);
const OtherInvestmentReportScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/reports/otherInvestment_report/otherInvestmentReportScreen"
    )
);
const AddProductScreen = lazy(
  () => import("src/pages/innerPages/add_product/addProductScreen")
);
const KeySettingScreen = lazy(
  () => import("src/pages/innerPages/key_setting/keySettingScreen")
);
const MessageSettingAddScreen = lazy(
  () => import("src/pages/innerPages/message_setting/messageSettingScreen")
);
const SMSSettingAddScreen = lazy(
  () => import("src/pages/innerPages/sms/smsSettingAddScreen")
);
const GroupRegisterScreen = lazy(
  () => import("src/pages/innerPages/group_register/groupRegisterScreen")
);
const AllUserReportScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/reports/allUserReportForCompany/allUserReportScreen"
    )
);
const SmsReportScreen = lazy(
  () => import("src/pages/innerPages/reports/sent_sms_report/smsReportScreen")
);
const UserReportScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/reports/allUserReportForGroupAdmin/userReportScreen"
    )
);
const ChangePasswordAddScreen = lazy(
  () => import("src/pages/innerPages/change_password/changePasswordAddScreen")
);
const AddProductTypeScreen = lazy(
  () => import("src/pages/innerPages/add_product_type/addProductTypeScreen")
);
const AddInvestmentTypeScreen = lazy(
  () =>
    import("src/pages/innerPages/add_investment_type/addInvestmentTypeScreen")
);
const UserEditScreen = lazy(
  () =>
    import("src/pages/innerPages/reports/allUserReportForGroupAdmin/editScreen")
);
const AddSubscriptionChargeScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/add_subscriptionCharge_groupAdmin/addSubscriptionChargeScreen"
    )
);
const GroupAdminScreen = lazy(
  () => import("src/pages/innerPages/dashborad/groupAdmin/groupAdminScreen")
);
const SubscriptionPaymentDetails = lazy(() =>
  import(
    "src/pages/outerPages/subscriptionPaymentDetails/subscriptionPaymentDetails"
  ).then((module) => ({ default: module.SubscriptionPaymentDetails }))
);
const CompanyDashboardScreen = lazy(
  () => import("src/pages/innerPages/dashborad/company/companyDashboardScreen")
);
const PaymentGatewaySettingScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/paymentGatewaySetting/paymentGatewaySettingScreen"
    )
);
const MemberDashboardScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/dashborad/memberDashboard/memberDashboardScreen"
    )
);
const AddGroupIncomeScreen = lazy(
  () => import("src/pages/innerPages/add_group_income/addGroupIncomeScreen")
);
const EmployeeDashboardScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/dashborad/employeeDashboard/employeeDashboardScreen"
    )
);
const GroupIncomeReportScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/reports/groupIncomeReport/groupIncomeReportScreen"
    )
);
const AllReportScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/reports/allUserReportForMember/allReportScreen"
    )
);
const ProfileScreen = lazy(
  () => import("src/pages/innerPages/profile/profileScreen")
);
const BachatGatReportScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/reports/bachatGat_report/bachatGatReportScreen"
    )
);
const AddCompanySubscriptionChargeScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/add_subscriptionCharge_company/addCompanySubscriptionChargeScreen"
    )
);

const MainRoute: React.FC = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(LOGOUT());
    LOGOUT();
  };

  return (
    <AuthInnerNavbar>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/Dashboard" element={<CompanySupportDashboardScreen />} />
          <Route path="/Member" element={<MemberRegistrationScreen />} />
          <Route path="/Employee" element={<EmployeeRegistrationScreen />} />
          <Route
            path="/Saving_Groups"
            element={<SavingsGroupRegistrationScreen />}
          />
          <Route path="/Register" element={<RegisterScreen />} />
          <Route path="/Add_Loan" element={<AddLoanScreen />} />
          <Route path="/Loan_Report" element={<LoanReportScreen />} />
          <Route path="/Pay_Installment" element={<PayInstallmentScreen />} />
          <Route
            path="/Installment_Report"
            element={<InstallmentReportScreen />}
          />
          <Route
            path="/Bachat_Report"
            element={<RegularBachatReportScreen />}
          />
          <Route path="/Other_Investment" element={<OtherInvestmentScreen />} />
          <Route path="/Expense_Type" element={<ExpenseTypeAddScreen />} />
          <Route path="/Expense_SubType" element={<ExpenseSubTypeScreen />} />
          <Route
            path="/Expense_Configuration"
            element={<ExpenseConfigurationScreen />}
          />
          <Route path="/Add_Expense" element={<AddExpenseClaimScreen />} />
          <Route
            path="/Expense_Claim_Report"
            element={<ExpenseClaimReportScreen />}
          />
          <Route
            path="/Investment_Report"
            element={<OtherInvestmentReportScreen />}
          />
          <Route path="/AddProduct" element={<AddProductScreen />} />
          <Route path="/Key_Setting" element={<KeySettingScreen />} />
          <Route
            path="/Message_Setting"
            element={<MessageSettingAddScreen />}
          />
          <Route path="/SMS_Setting" element={<SMSSettingAddScreen />} />
          <Route path="/Group_Register" element={<GroupRegisterScreen />} />
          <Route path="/User_Report" element={<AllUserReportScreen />} />
          <Route path="/Sent_SMS_Report" element={<SmsReportScreen />} />
          <Route path="/All_User" element={<UserReportScreen />} />
          <Route path="/All_User_Report" element={<AllReportScreen />} />
          <Route
            path="/change_password"
            element={<ChangePasswordAddScreen />}
          />
          <Route path="/Product_Type" element={<AddProductTypeScreen />} />
          <Route
            path="/Investment_Type"
            element={<AddInvestmentTypeScreen />}
          />
          <Route
            path="/Investment_Type"
            element={<AddInvestmentTypeScreen />}
          />
          <Route path="/User_Edit" element={<UserEditScreen />} />
          <Route path="/user_edit" element={<AllUserReportScreen />} />

          <Route
            path="/Subscription_Charge"
            element={<AddSubscriptionChargeScreen />}
          />
          <Route path="/GroupAdminDashboard" element={<GroupAdminScreen />} />
          <Route
            path="/SubscriptionPayment"
            element={
              <SubscriptionPaymentDetails
                isOpen={true}
                title={""}
                negative_button_click={function (value: boolean): void {
                  throw new Error("Function not implemented.");
                }}
              />
            }
          />
          <Route
            path="/CompanyDashboard"
            element={<CompanyDashboardScreen />}
          />
          <Route path="/Service" element={<PaymentGatewaySettingScreen />} />
          <Route path="/MemberDashboard" element={<MemberDashboardScreen />} />
          <Route
            path="/EmployeeDashboard"
            element={<EmployeeDashboardScreen />}
          />
          <Route path="/Add_Income" element={<AddGroupIncomeScreen />} />
          <Route path="/Income_Report" element={<GroupIncomeReportScreen />} />
          <Route
            path="/Profile"
            element={<ProfileScreen IsUserProfileUploaded={false} />}
          />
          <Route
            path="/Bachat_Gat_Report"
            element={<BachatGatReportScreen />}
          />
          <Route
            path="/Company_Subscription"
            element={<AddCompanySubscriptionChargeScreen />}
          />

          <Route
            path="/Logout"
            element={<LogoutComponent onLogout={handleLogout} />}
          />
        </Routes>
      </Suspense>
    </AuthInnerNavbar>
  );
};

export default MainRoute;

interface LogoutComponentProps {
  onLogout: () => void;
}

const LogoutComponent: React.FC<LogoutComponentProps> = ({ onLogout }) => {
  React.useEffect(() => {
    onLogout();
  }, [onLogout]);

  return <div>Logging out...</div>;
};
