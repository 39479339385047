import {
  PostAPI,
  PostAPIWithoutRequest,
} from "src/component/api/helper/apiHelper";

export const Insert = async (request: string) => {
  return PostAPI(request, "MemberRegistration/Insert");
};
export const SelectMemberForGroupAdmin = async () => {
  return PostAPIWithoutRequest(
    "MemberRegistration/SelectRegisteredMemberForGroupAdmin"
  );
};
export const SelectRegistrationID = async (request: string) => {
  return PostAPI(request, "MemberRegistration/SelectRegistrationID");
};
export const Select = async (request: string) => {
  return PostAPI(request, "MemberRegistration/Select");
};
export const SelectMemberDetails = async (request: string) => {
  return PostAPI(request, "MemberRegistration/SelectMemberDetails");
};
